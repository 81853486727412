import React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

export default () => (
  <Layout>
    <div className="content px2 py3">
      <SEO
        title="Page not found"
        meta={[
          {
            name: "description",
            content: "Surrey Health and Beauty Spa not found page",
          },
          { name: "keywords", content: "beauty, spa, surrey" },
        ]}
      />

      <h1 className="h4 caps bold mt0 mb3 center">Page not found</h1>

      <p className="center">
        <Link to={"/"} className="block mx-auto sm-mx0 mb1 sm-mb0">
          Go back home →
        </Link>
      </p>
    </div>
  </Layout>
)
